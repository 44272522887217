import React from 'react';
import { LayoutAdmin } from '../../core';

const OverViewVendor = () => {
     
        return (
            <LayoutAdmin     title={'Overview Vendor'}
            pages={['Overview']}
          
           >
          
          
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              {/*begin::Content wrapper*/}
              <div className="d-flex flex-column flex-column-fluid">
                {/*begin::Content*/}
                <div id="kt_app_content" className="app-content px-0">
                  {/*begin:: hala Add */}
                  {/*begin::Row1*/}
                  <div className="row g-5 g-xl-10 mb-xl-10"style={{display:"none"}}>
                    {/*begin::Col*/}
                   
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                      {/*begin::Chart widget 3*/}
                      <div className="card card-flush overflow-hidden h-md-100">
                        {/*begin::Header*/}
                        <div className="card-header py-5">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                              Sales This Months
                            </span>
                          </h3>
                          {/*end::Title*/}
                          {/*begin::Toolbar*/}
                          <div className="card-toolbar">
                            {/*begin::Menu*/}
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                            {/*begin::Menu 2*/}
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                              data-kt-menu="true"
                            >
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                                  Quick Actions
                                </div>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu separator*/}
                              <div className="separator mb-3 opacity-75" />
                              {/*end::Menu separator*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Ticket
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Customer
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div
                                className="menu-item px-3"
                                data-kt-menu-trigger="hover"
                                data-kt-menu-placement="right-start"
                              >
                                {/*begin::Menu item*/}
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  <span className="menu-title">New Group</span>
                                  <span className="menu-arrow" />
                                </a>
                                {/*end::Menu item*/}
                                {/*begin::Menu sub*/}
                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Admin Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Staff Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Member Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                </div>
                                {/*end::Menu sub*/}
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Contact
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu separator*/}
                              <div className="separator mt-3 opacity-75" />
                              {/*end::Menu separator*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <div className="menu-content px-3 py-3">
                                  <a
                                    className="btn btn-primary btn-sm px-4"
                                    href="#"
                                  >
                                    Generate Reports
                                  </a>
                                </div>
                              </div>
                              {/*end::Menu item*/}
                            </div>
                            {/*end::Menu 2*/}
                            {/*end::Menu*/}
                          </div>
                          {/*end::Toolbar*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                          {/*begin::Chart*/}
                          <div
                            id="kt_charts_widget_16_chart"
                            className="min-h-auto ps-4 pe-6"
                            style={{ height: 350 }}
                          />
                          {/*end::Chart*/}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Chart widget 3*/}
                    </div>
                    {/*end::Col*/}

                    {/*begin::Col*/}
                    <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                      {/*begin::Chart widget 3*/}
                      <div className="card card-flush overflow-hidden h-md-100">
                        {/*begin::Header*/}
                        <div className="card-header py-5">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                              Sales This Months
                            </span>
                          </h3>
                          {/*end::Title*/}
                          {/*begin::Toolbar*/}
                          <div className="card-toolbar">
                            {/*begin::Menu*/}
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                            {/*begin::Menu 2*/}
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                              data-kt-menu="true"
                            >
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                                  Quick Actions
                                </div>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu separator*/}
                              <div className="separator mb-3 opacity-75" />
                              {/*end::Menu separator*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Ticket
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Customer
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div
                                className="menu-item px-3"
                                data-kt-menu-trigger="hover"
                                data-kt-menu-placement="right-start"
                              >
                                {/*begin::Menu item*/}
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  <span className="menu-title">New Group</span>
                                  <span className="menu-arrow" />
                                </a>
                                {/*end::Menu item*/}
                                {/*begin::Menu sub*/}
                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Admin Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Staff Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a
                                      href="#"
                                      className="menu-link px-3"
                                    >
                                      Member Group
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                </div>
                                {/*end::Menu sub*/}
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a
                                  href="#"
                                  className="menu-link px-3"
                                >
                                  New Contact
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu separator*/}
                              <div className="separator mt-3 opacity-75" />
                              {/*end::Menu separator*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <div className="menu-content px-3 py-3">
                                  <a
                                    className="btn btn-primary btn-sm px-4"
                                    href="#"
                                  >
                                    Generate Reports
                                  </a>
                                </div>
                              </div>
                              {/*end::Menu item*/}
                            </div>
                            {/*end::Menu 2*/}
                            {/*end::Menu*/}
                          </div>
                          {/*end::Toolbar*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Card body*/}
                        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                          {/*begin::Chart*/}
                          <div
                            id="kt_charts_widget_16_chart"
                            className="min-h-auto ps-4 pe-6"
                            style={{ height: 350 }}
                          />
                          {/*end::Chart*/}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Chart widget 3*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Row1*/}
                  {/*begin::Row2*/}
                  <div className="row gy-5 g-xl-10 mx-1">
                    {/*begin::Card*/}
                    <div className="card">
                      {/*begin::Card header*/}
                      <div className="card-header border-0 pt-6">
                        {/*begin::Card title*/}
                        <div className="card-title">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                              Outstanding Statements
                            </span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6">
                              Total 2,356 Items in the Outstanding 
                            </span>
                          </h3>
                          {/*end::Title*/}
                        </div>
                        {/*begin::Card title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                          {/*begin::Actions*/}
                          <div className="card-toolbar">
                            {/*begin::Filters*/}
                            <div className="d-flex flex-stack flex-wrap gap-4">
                              {/*begin::Destination*/}
                              <div className="d-flex align-items-center fw-bold">
                                {/*begin::Label*/}
                                <div className="text-muted fs-7 me-2">Cateogry</div>
                                {/*end::Label*/}
                                {/*begin::Select*/}
                                <select
                                  className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-dropdown-css-class="w-150px"
                                  data-placeholder="Select an option"
                                >
                                  <option />
                                  <option value="Show All" selected="selected">
                                    Show All
                                  </option>
                                  <option value="a">Category A</option>
                                  <option value="b">Category B</option>
                                </select>
                                {/*end::Select*/}
                              </div>
                              {/*end::Destination*/}
                              {/*begin::Status*/}
                              <div className="d-flex align-items-center fw-bold">
                                {/*begin::Label*/}
                                <div className="text-muted fs-7 me-2">Status</div>
                                {/*end::Label*/}
                                {/*begin::Select*/}
                                <select
                                  className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-dropdown-css-class="w-150px"
                                  data-placeholder="Select an option"
                                  data-kt-table-widget-5="filter_status"
                                >
                                  <option />
                                  <option value="Show All" selected="selected">
                                    Show All
                                  </option>
                                  <option value="In  ">In</option>
                                  <option value="Out of  ">Out of</option>
                                  <option value="Low  ">Low</option>
                                </select>
                                {/*end::Select*/}
                              </div>
                              {/*end::Status*/}
                              {/*begin::Search*/}
                              <a
                                href="apps/ecommerce/catalog/products.html"
                                className="btn btn-light btn-sm"
                              >
                                View
                              </a>
                              {/*end::Search*/}
                            </div>
                            {/*begin::Filters*/}
                          </div>
                          {/*end::Actions*/}
                        </div>
                        {/*end::Card toolbar*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body pt-0">
                        <div className="table-responsive">
                          {/*begin::Table*/}
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-5"
                            id="kt_subscriptions_table"
                          >
                            {/*begin::Table head*/}
                            <thead>
                              {/*begin::Table row*/}
                              <tr>
                                <th className="min-w-200px">
                                  status
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  reason
                                  <a
                                    href="#"
                                    className="text-hover-primary"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  description
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  Incorrect Invoice
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  Scource
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-150px">
                                  cc
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  Vendor Number
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  Vendor name
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  invoice number
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  invoice Date
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  GMM Amount
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-125px">
                                  Currency
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-125px">
                                  PO
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-125px">
                                  Due
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-125px">
                                  ATh
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-200px">
                                  payment Status
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                <th className="min-w-125px">
                                  Entered
                                  <a
                                    href="#"
                                    className="text-hover-primary ps-4"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </a>
                                </th>
                                {/*end::Table row*/}
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody className="text-gray-600 fw-semibold">
                              {/* begin::record1 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td>Pending Review</td>
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Date entry Error "
                                  />
                                </td>
                                {/*end::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Auro Payment mold"
                                  />
                                </td>
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={1}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    USA
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record1 */}
                              {/* begin::record2 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td />
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td />
                                {/*end::Status=*/}
                                <td />
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={1}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    USA
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record2 */}
                              {/* begin::record3 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td>Pending Review</td>
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Date entry Error "
                                  />
                                </td>
                                {/*end::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Auro Payment mold"
                                  />
                                </td>
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={1}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    USA
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record3 */}
                              {/* begin::record4 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td />
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td />
                                {/*end::Status=*/}
                                <td />
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={0}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    GEP
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record4 */}
                              {/* begin::record5 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td>Pending Review</td>
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Date entry Error "
                                  />
                                </td>
                                {/*end::Status=*/}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="name"
                                    defaultValue="Auro Payment mold"
                                  />
                                </td>
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={1}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    USA
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record5 */}
                              {/* begin::record6 */}
                              <tr>
                                {/*begin::Customer=*/}
                                <td />
                                {/*end::Customer=*/}
                                {/*begin::Status=*/}
                                <td />
                                {/*end::Status=*/}
                                <td />
                                {/*begin::Checkbox*/}
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue={1}
                                    />
                                  </div>
                                </td>
                                {/*end::Checkbox*/}
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    AS101
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    100
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    374AC4
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-warning">
                                    Control value description
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    1254789
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    500.005.285
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    USA
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    30005678
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light-primary">
                                    4/15/22
                                  </div>
                                </td>
                                <td>
                                  <div className="badge py-5 px-3 badge-light">i</div>
                                </td>
                                <td>Due</td>
                                <td className="text-end">Aug 19, 2023</td>
                              </tr>
                              {/* end::record6 */}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                      </div>
                      {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                  </div>
                  {/*end::Row2*/}
                  {/*begin::Row3*/}
                  <div className="row gy-5 g-xl-10 mx-1 mt-1">
                    {/*begin::Card*/}
                    <div className="card">
                      {/*begin::Card header*/}
                      <div className="card-header border-0 pt-6">
                        {/*begin::Card title*/}
                        <div className="card-title">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">
                               
                              Report
                            </span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6">
                              Total 2,356 Items in the
                            </span>
                          </h3>
                          {/*end::Title*/}
                        </div>
                        {/*begin::Card title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar">
                          {/*begin::Actions*/}
                          <div className="card-toolbar">
                            {/*begin::Filters*/}
                            <div className="d-flex flex-stack flex-wrap gap-4">
                              {/*begin::Search*/}
                              <a
                                href="#"
                                className="btn btn-light btn-sm"
                              >
                                All
                              </a>
                              {/*end::Search*/}
                            </div>
                            {/*begin::Filters*/}
                          </div>
                          {/*end::Actions*/}
                        </div>
                        {/*end::Card toolbar*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body pt-0">
                        <div className="table-responsive">
                          {/*begin::Table*/}
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-5"
                            id="kt_subscriptions_table"
                          >
                            {/*begin::Table head*/}
                            <thead>
                              {/*begin::Table row*/}
                              <tr>
                                <th className="min-w-200px">
                                  id
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-200px">
                                  description
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-200px">
                                  Assigned To
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-200px">
                                  Due
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-200px">
                                  InQueut
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-150px">
                                  Notfication
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-200px">
                                  Most Recent
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                <th className="min-w-125px">
                                  Notes
                                  <button
                                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                  >
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={2}
                                          width={20}
                                          height={20}
                                          rx={4}
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={11}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={15}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x={7}
                                          y={11}
                                          width="2.6"
                                          height="2.6"
                                          rx="1.3"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </button>
                                </th>
                                {/*end::Table row*/}
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody className="text-gray-600 fw-semibold">
                              {/* begin::record1 */}
                              <tr>
                                <td>11112255</td>
                                <td>
                                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </td>
                                {/*end::Status=*/}
                                <td>Lorem ipsum</td>
                                <td>4/15/22</td>
                                <td>od 1h 23m</td>
                                <td />
                                <td>4/15/22 6:01</td>
                                <td />
                              </tr>
                              {/* end::record1 */}
                              {/* begin::record1 */}
                              <tr>
                                <td>11112255</td>
                                <td>
                                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </td>
                                {/*end::Status=*/}
                                <td>Lorem ipsum</td>
                                <td>4/15/22</td>
                                <td>od 1h 23m</td>
                                <td />
                                <td>4/15/22 6:01</td>
                                <td />
                              </tr>
                              {/* end::record1 */}
                              {/* begin::record1 */}
                              <tr>
                                <td>11112255</td>
                                <td>
                                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </td>
                                {/*end::Status=*/}
                                <td>Lorem ipsum</td>
                                <td>4/15/22</td>
                                <td>od 1h 23m</td>
                                <td />
                                <td>4/15/22 6:01</td>
                                <td />
                              </tr>
                              {/* end::record1 */}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                      </div>
                      {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                  </div>
                  {/*end::Row3*/}
                  {/* end::hala Add */}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Content wrapper*/}
          
            </div>

            
            
          
          
            </LayoutAdmin>
            ) ;
}
 
export default OverViewVendor;