import React from "react";
import { MenuItem, SubItem } from "../../index";

const HeaderCleanse = () => {
  return (
    <>
      <SubItem title="Back" namePage="home" isNotSubMenu={true} />
      <SubItem title="Overview" namePage="cleanse_overview" isNotSubMenu={true} />
      <DataCleansItems />
      <SupplierInvitationItem />
      <BedrockVerifictionItems />
    </>
  );
};

export default HeaderCleanse;
const DataCleansItems = () => (
  <MenuItem title="Data Cleans">
       <SubItem title="Overview" namePage="data_cleanse_overview" />
    <SubItem title="Original Vendor Master" namePage="original_vendor" />
    <SubItem title="Vendor Archive" namePage="vendor_archive" />
    <SubItem title="Ready To Data Cleans" namePage="ready_to_data_cleans" />

    <SubItem title="Data Final" namePage="data_final" />
    <SubItem title="Supplier Invitation" namePage="vendor_invitation" />
    <SubItem title="Report" namePage="report" />
    <SubItem title="Setting" namePage="setting" />
    <SubItem title="Import Supplier" namePage="import_supplier" />
  </MenuItem>
);
const SupplierInvitationItem = () => (
  <MenuItem title="Supplier Invitation">
           <SubItem title="Overview" namePage="supplier_invite_cleanse_overview" />
    <SubItem title=" Supplier Invite" namePage="supplier_invite_si" />
    <SubItem title="Approval List  " namePage="approval_list_si" />
  </MenuItem>
);

function BedrockVerifictionItems() {
  return (
    <MenuItem title="Bedrock Verifiction">
             <SubItem title="Overview" namePage="verification_cleanse_overview" />
      <SubItem title="Bedrock Verifiction" namePage="bedrock_verifiction" />
      <SubItem title="Bank Verification" namePage="bank_verification" />
      <SubItem title="Global Verification" namePage="global_verification" />
    </MenuItem>
  );
}
