import React from "react";
import {  SubItem } from "../../index";

const HeaderVerify = () => {
  return (
    <>
      <SubItem title="Back" namePage="home" isNotSubMenu={true} />
      <SubItem title="Overview" namePage="verify_overview" isNotSubMenu={true} />
  
    </>
  );
};

export default HeaderVerify;

