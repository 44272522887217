import React from "react";
import LayoutPrevent from "../LayoutPrevent";
import { BtnUpload } from "../component-prevent";

const OverviewPrevent = () => {
  return (
    <LayoutPrevent
      extraAction={
       <BtnUpload/>
      }
      title={"Prevention"}
      pages={["Overview"]}
    >
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content px-0">
            {/*begin:: hala Add */}
            {/*begin::Row1*/}
            <div className="row gy-5 g-xl-10 "style={{display:"none"}}>
              {/*begin::Col*/}
              <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0 ">
                <div className="card card-flush overflow-hidden h-md-50">
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Root Cause
                      </span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">
                        $17.72M Erroneous Payments Prevented
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                      <div
                        data-kt-daterangepicker="true"
                        data-kt-daterangepicker-opens="left"
                        data-kt-daterangepicker-range="today"
                        className="btn btn-sm btn-light d-flex align-items-center px-4"
                      >
                        {/*begin::Display range*/}
                        <div className="text-gray-600 fw-bold">
                          Loading date range...
                        </div>
                        {/*end::Display range*/}
                        {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                        <span className="svg-icon svg-icon-1 ms-2 me-0">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      {/*end::Daterangepicker*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex align-items-end p-0">
                    {/*begin::Chart*/}
                    <div
                      id="kt_charts_widget_root_cause"
                      className="min-h-auto w-100 ps-4 pe-6"
                      style={{ height: 300 }}
                    ></div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*begin::Card widget 7*/}
                <div className="pt-0 card card-flush h-md-50 mt-5 mb-5 mb-xl-10">
                  {/*begin::Header*/}
                  <div className="card-header py-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Top Supplier
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Menu*/}
                      <button
                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-overflow="true"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={4}
                              fill="currentColor"
                            />
                            <rect
                              x={11}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={15}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={7}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </button>
                      {/*begin::Menu 2*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                        data-kt-menu="true"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                            Quick Actions
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mb-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Ticket
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Customer
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div
                          className="menu-item px-3"
                          data-kt-menu-trigger="hover"
                          data-kt-menu-placement="right-start"
                        >
                          {/*begin::Menu item*/}
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            <span className="menu-title">New Group</span>
                            <span className="menu-arrow" />
                          </a>
                          {/*end::Menu item*/}
                          {/*begin::Menu sub*/}
                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Admin Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Staff Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Member Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu sub*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Contact
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mt-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content px-3 py-3">
                            <a
                              className="btn btn-primary btn-sm px-4"
                              href="#"
                            >
                              Generate Reports
                            </a>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu 2*/}
                      {/*end::Menu*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex align-items-end p-0">
                    {/*begin::Chart*/}
                    <div
                      id="kt_charts_widget_supplier_chart"
                      className="h-325px w-100 min-h-auto ps-4 pe-6"
                      style={{ minHeight: 340 }}
                    />
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 7*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                {/*begin::Card widget 6*/}
                <div className=" card card-flush h-md-50 mb-5 mb-xl-5 ">
                  {/*begin::Header*/}
                  <div className="card-header py-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Summary Results
                      </span>
                    </h3>
                    {/*end::Title*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="p-0 card-body d-flex align-items-start ">
                    {/*begin::Chart*/}
                    <div
                      id="kt_charts_widget_summary_result_prevent_chart"
                      className="w-100"
                      style={{ height: 350 }}
                    ></div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 6*/}
                {/*begin::Card widget 7*/}
                <div className="pt-0 card card-flush h-md-50 mb-5 mb-xl-10">
                  {/*begin::Header*/}
                  <div className="card-header py-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Spend VS Prevent
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Menu*/}
                      <button
                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-overflow="true"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={4}
                              fill="currentColor"
                            />
                            <rect
                              x={11}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={15}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={7}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </button>
                      {/*begin::Menu 2*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                        data-kt-menu="true"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                            Quick Actions
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mb-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Ticket
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Customer
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div
                          className="menu-item px-3"
                          data-kt-menu-trigger="hover"
                          data-kt-menu-placement="right-start"
                        >
                          {/*begin::Menu item*/}
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            <span className="menu-title">New Group</span>
                            <span className="menu-arrow" />
                          </a>
                          {/*end::Menu item*/}
                          {/*begin::Menu sub*/}
                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Admin Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Staff Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="#"
                                className="menu-link px-3"
                              >
                                Member Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu sub*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                          >
                            New Contact
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mt-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content px-3 py-3">
                            <a
                              className="btn btn-primary btn-sm px-4"
                              href="#"
                            >
                              Generate Reports
                            </a>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu 2*/}
                      {/*end::Menu*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex align-items-end p-0">
                    {/*begin::Chart*/}
                    <div
                      id="kt_charts_widget_spend_vs_prevent_1_chart"
                      className="min-h-auto ps-4 pe-6 w-100"
                      style={{ height: 350 }}
                    />
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 7*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row1*/}
            {/*begin::Row2*/}
            <div className="row gy-5 g-xl-10 ">
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="card-title">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Prevent Report
                      </span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">
                        Total 2,356 Items in the Prevent
                      </span>
                    </h3>
                    {/*end::Title*/}
                  </div>
                  {/*begin::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Actions*/}
                    <div className="card-toolbar">
                      {/*begin::Filters*/}
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        {/*begin::Destination*/}
                        <div className="d-flex align-items-center fw-bold">
                          {/*begin::Label*/}
                          <div className="text-muted fs-7 me-2">Cateogry</div>
                          {/*end::Label*/}
                          {/*begin::Select*/}
                          <select
                            className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                            data-control="select2"
                            data-hide-search="true"
                            data-dropdown-css-class="w-150px"
                            data-placeholder="Select an option"
                          >
                            <option />
                            <option value="Show All" selected="selected">
                              Show All
                            </option>
                            <option value="a">Category A</option>
                            <option value="b">Category B</option>
                          </select>
                          {/*end::Select*/}
                        </div>
                        {/*end::Destination*/}
                        {/*begin::Status*/}
                        <div className="d-flex align-items-center fw-bold">
                          {/*begin::Label*/}
                          <div className="text-muted fs-7 me-2">Status</div>
                          {/*end::Label*/}
                          {/*begin::Select*/}
                          <select
                            className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                            data-control="select2"
                            data-hide-search="true"
                            data-dropdown-css-class="w-150px"
                            data-placeholder="Select an option"
                            data-kt-table-widget-5="filter_status"
                          >
                            <option />
                            <option value="Show All" selected="selected">
                              Show All
                            </option>
                            <option value="In  ">In</option>
                            <option value="Out of  ">Out of</option>
                            <option value="Low  ">Low</option>
                          </select>
                          {/*end::Select*/}
                        </div>
                        {/*end::Status*/}
                        {/*begin::Search*/}
                        <a
                          href="apps/ecommerce/catalog/products.html"
                          className="btn btn-light btn-sm"
                        >
                          View Excel
                        </a>
                        {/*end::Search*/}
                      </div>
                      {/*begin::Filters*/}
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                  <div className="table-responsive text-capitalize">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_subscriptions_table"
                    >
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr>
                          <th className="min-w-200px">
                            status
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            reason
                            <a
                              href="#"
                              className="text-hover-primary"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            description
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            Incorrect Invoice
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            Scource
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-150px">
                            cc
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            Vendor Number
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            Vendor name
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            invoice number
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            invoice Date
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            GMM Amount
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-125px">
                            Currency
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-125px">
                            PO
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-125px">
                            Due
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-125px">
                            ATh
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-200px">
                            payment Status
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          <th className="min-w-125px">
                            Entered
                            <a
                              href="#"
                              className="text-hover-primary ps-4"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                              <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </a>
                          </th>
                          {/*end::Table row*/}
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody className="text-gray-600 fw-semibold">
                        {/* begin::record1 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td>Pending Review</td>
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Date entry Error "
                            />
                          </td>
                          {/*end::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Auro Payment mold"
                            />
                          </td>
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={1}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              USA
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record1 */}
                        {/* begin::record2 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td />
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td />
                          {/*end::Status=*/}
                          <td />
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={1}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              USA
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record2 */}
                        {/* begin::record3 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td>Pending Review</td>
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Date entry Error "
                            />
                          </td>
                          {/*end::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Auro Payment mold"
                            />
                          </td>
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={1}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              USA
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record3 */}
                        {/* begin::record4 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td />
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td />
                          {/*end::Status=*/}
                          <td />
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={0}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              GEP
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record4 */}
                        {/* begin::record5 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td>Pending Review</td>
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Date entry Error "
                            />
                          </td>
                          {/*end::Status=*/}
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="name"
                              defaultValue="Auro Payment mold"
                            />
                          </td>
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={1}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              USA
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record5 */}
                        {/* begin::record6 */}
                        <tr>
                          {/*begin::Customer=*/}
                          <td />
                          {/*end::Customer=*/}
                          {/*begin::Status=*/}
                          <td />
                          {/*end::Status=*/}
                          <td />
                          {/*begin::Checkbox*/}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={1}
                              />
                            </div>
                          </td>
                          {/*end::Checkbox*/}
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              AS101
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              100
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              374AC4
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-warning">
                              Control value description
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              1254789
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              500.005.285
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              USA
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              30005678
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light-primary">
                              4/15/22
                            </div>
                          </td>
                          <td>
                            <div className="badge py-5 px-3 badge-light">i</div>
                          </td>
                          <td>Due</td>
                          <td className="text-end">Aug 19, 2023</td>
                        </tr>
                        {/* end::record6 */}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Row2*/}
            {/*begin::Row3*/}
            <div className="row gy-5 g-xl-10 mx-1 mt-1">
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="card-title">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Historical
                      </span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">
                        Total 2,356 Items in the Completed
                      </span>
                    </h3>
                    {/*end::Title*/}
                  </div>
                  {/*begin::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Actions*/}
                    <div className="card-toolbar">
                      {/*begin::Filters*/}
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        {/*begin::Search*/}
                        <a
                          href="#"
                          className="btn btn-light btn-sm"
                        >
                          All
                        </a>
                        {/*end::Search*/}
                      </div>
                      {/*begin::Filters*/}
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_subscriptions_table"
                    >
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr>
                          <th className="min-w-80px">
                            id
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-150px">
                            Status
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-200px">
                            Reason
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-200px">
                            Staff
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-200px">
                            Description
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-200px">
                            Incorrect Invoice
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-200px">
                            Source
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-125px">
                            Supplier Number
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-125px">
                            Supplier Name
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-125px">
                            Invoice Number
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th> 
                          <th className="min-w-125px">
                            Invoice Date
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th> 
                          <th className="min-w-125px">
                            Gross Amount
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th> 
                          <th className="min-w-125px">
                            Currency
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th> 
                          <th className="min-w-125px">
                            PO
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          <th className="min-w-125px">
                            Payment Date
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th> 
                          <th className="min-w-125px">
                            Payment Type
                            <button
                              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-overflow="true"
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={4}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={11}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={15}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y={11}
                                    width="2.6"
                                    height="2.6"
                                    rx="1.3"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                          </th>
                          {/*end::Table row*/}
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody className="text-gray-600 fw-semibold">
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Stop</td>
                          {/*end::Status=*/}
                          <td>Split Payment</td>
                          <td>John Chem</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* end::record1 */}
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Stop</td>
                          {/*end::Status=*/}
                          <td>Multiple Product/Services</td>
                          <td>John Chem</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* end::record1 */}
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Stop</td>
                          {/*end::Status=*/}
                          <td>Multiple Product/Services</td>
                          <td>Rita Belle</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Process</td>
                          {/*end::Status=*/}
                          <td>Same Invoice</td>
                          <td>John Chem</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Process</td>
                          {/*end::Status=*/}
                          <td>Same Invoice</td>
                          <td>Rita Belle</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Process</td>
                          {/*end::Status=*/}
                          <td>Same Invoice</td>
                          <td>Rita Belle</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Process</td>
                          {/*end::Status=*/}
                          <td>Same Invoice</td>
                          <td>Rita Belle</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Process</td>
                          {/*end::Status=*/}
                          <td>Same Invoice</td>
                          <td>Rita Belle</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* end::record1 */}
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Stop</td>
                          {/*end::Status=*/}
                          <td>Different Location</td>
                          <td>John Chem</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* end::record1 */}
                        {/* begin::record1 */}
                        <tr>
                          <td />
                          <td>Stop</td>
                          {/*end::Status=*/}
                          <td>2nd Payment</td>
                          <td>John Chem</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {/* end::record1 */}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Row3*/}
            {/* end::hala Add */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
    </LayoutPrevent>
  );
};

export default OverviewPrevent;
