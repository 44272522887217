import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
//Components

import Input from "./Input";

import { CheckBox } from "../../../../index";

function Form() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const form = useRef(null);
  const dispatch = useDispatch();
  // const { user, token, loading, error, status } = authSlice(
  //   (state) => state.auth
  // )
  const { loading, user, error, status } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const submitForm = (event) => {
    if (email && password) {
      event.target.setAttribute("data-kt-indicator", "on");
      setTimeout(function () {
        event.target.removeAttribute("data-kt-indicator");
        event.target.disabled = !1;
        window.Swal.fire2({
          text: "You have successfully logged in!",
          icon: "success",
          buttonsStyling: !1,
          confirmButtonText: "Ok, got it!",
          customClass: { confirmButton: "btn btn-primary" },
        }).then(function () {
          console.log("tessst");
          // if (event.target.isConfirmed) {

          console.log("form:", form.current);
          form.current.querySelector('[name="email"]').value = "";
          form.current.querySelector('[name="password"]').value = "";
          var i = form.current.getAttribute("data-kt-redirect-url");
          navigate(i);
          // }
        });
      }, 2e3);
    } else {
      window.Swal.fire2({
        text: "Sorry, looks like there are some errors detected, please try again.",
        icon: "error",
        buttonsStyling: !1,
        confirmButtonText: "Ok, got it!",
        customClass: { confirmButton: "btn btn-primary" },
      });
    }

    // dispatch(signIn({ email, password }));
    // if (status === "success") {
    //   navigate("/Home");
    // } else if (status === "rejected") {
    //   navigate("/unexpected");
    // }
  };

  return (
    <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        data-kt-redirect-url="/Home"
        action="#"
        ref={form}
        //onSubmit={handleSubmit(submitForm)}
        // onSubmit={onSubmit}
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign In</h1>

          <div className="text-gray-500 fw-semibold fs-6">
            Welcome In Bedrock
          </div>
        </div>

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Sign In to Your Account
          </span>
        </div>

        <div className="fv-row mb-8">
          <Input
            id="email"
            type={"email"}
            placeHolder={"Email"}
            setVal={(e) => setEmail(e)}
          />
        </div>
        <div className="fv-row mb-3">
          <Input
            id="password"
            type={"password"}
            placeHolder={"Password"}
            setVal={(e) => setPassword(e)}
          />
        </div>

        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          <a
            href=""
            // href="authentication/layouts/overlay/reset-password.html"
            className="link-primary"
          >
            Forgot Password ?
          </a>
        </div>

        <div className="d-grid mb-10">
          <button
            type="button"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            onClick={submitForm}
          >
            <span className="indicator-label">Sign In</span>

            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          <CheckBox title="Remember Me ." isHint={true} />
        </div>
      </form>
    </div>
  );
}

export default Form;
