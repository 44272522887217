import React from "react";
import {  MenuItem, SubItem } from "../../../index";

const HeaderAdmin = () => {
  return (
  
        <>
          <Dashboard />
          <VendorsItems />
      
          <SubItem title="Recover" namePage="audit_overview" isNotSubMenu={true}/>
          <SubItem title="Verify" namePage="verify_overview" isNotSubMenu={true}/>
          
          <SubItem title="Cleanse" namePage="cleanse_overview" isNotSubMenu={true}/>
          <SubItem title="Prevent" namePage="prevent_overview" isNotSubMenu={true}/>
          <RequestHelpItem />
          <UtilitiesItems />
          <SetupItems />
        </>
     
  );
};

export default HeaderAdmin;

function UtilitiesItems() {
  return (
    <MenuItem title="Utilities">
      <SubItem title="Announcements" namePage="announcements_utilities" />
      <SubItem title="Activity Log" namePage="activity_log_utilities" />
    </MenuItem>
  );
}

function Dashboard() {
  return (
    <MenuItem title="Dashboard">
      <SubItem title="Overview" namePage="home" />
    </MenuItem>
  );
}

function VendorsItems() {
  return (
    <MenuItem title="Vendors">
        <SubItem title="OverView" namePage="overview_vendor"/>
      <SubItem title="Vendors" namePage="vendor_page" />
    </MenuItem>
  );
}

function SetupItems() {
  return (
    <MenuItem title="Setup">
      <SubItem title="Staff" namePage="staff_setup" />
      <SubItem
        title="Finance"
        tabs={[{ title: "Currencies", namePage: "currencies_setup" }]}
      />
      <SubItem title="Email Templates" namePage="email_templates_setup" />
      <SubItem title="Bedrock Onboarding" namePage="onboarding_setup" />
      <SubItem title=" GDPR" namePage="gdpr_setup" />
      <SubItem title=" Roles" namePage="roles_setup" />
      <SubItem title="Settings" namePage="setting_setup" />
      <SubItem title="Features Enable" namePage="features_enable_setup" />
      <SubItem title="Help" namePage="" />
    </MenuItem>
  );
}


const RequestHelpItem = () => (
  <MenuItem title="Request Help">
    <SubItem title="Request Help" namePage="request_help" />
  </MenuItem>
);
