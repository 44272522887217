import React from "react";
import { LayoutPrevent } from "../index";



const OverviewInView = () => {
  return (
    <LayoutPrevent title={"Inview"} pages={["Overview"]}>

<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
  {/*begin::Content wrapper*/}
  <div className="d-flex flex-column flex-column-fluid">
    {/*begin::Content*/}
    <div id="kt_app_content" className="app-content px-0">
      {/*begin:: hala Add */}
      {/*begin::Row1*/}
      <div className="row g-5 g-xl-10 mb-xl-10"style={{display:"none"}}>
        {/*begin::Col*/}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          {/*begin::Card widget 4*/}
          <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            {/*begin::Header*/}
            <div className="card-header pt-5">
              {/*begin::Title*/}
              <div className="card-title d-flex flex-column">
                {/*begin::Info*/}
                <div className="d-flex align-items-center">
                  {/*begin::Currency*/}
                  <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                    $
                  </span>
                  {/*end::Currency*/}
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    22,800
                  </span>
                  {/*end::Amount*/}
                  {/*begin::Badge*/}
                  <span className="badge badge-light-primary fs-base">
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.5"
                          x={13}
                          y={6}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(90 13 6)"
                          fill="currentColor"
                        />
                        <path
                          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}2.2%
                  </span>
                  {/*end::Badge*/}
                </div>
                {/*end::Info*/}
                {/*begin::Subtitle*/}
                <span className="text-gray-400 pt-1 fw-semibold fs-6">
                  Expected payments
                </span>
                {/*end::Subtitle*/}
              </div>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end px-0 pb-0">
              <div
                id="kt_charts_widget_28"
                style={{ minWidth: 150, minHeight: 150 }}
                className="w-100"
                data-kt-size={70}
                data-kt-line={11}
              />
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 4*/}
          {/*begin::Card widget 5*/}
          <div className="card overflow-hidden h-md-50 mb-5 mb-xl-10">
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
              {/*begin::Statistics*/}
              <div className="mb-0 px-9">
                {/*begin::Statistics*/}
                <div className="d-flex align-items-center mb-2">
                  {/*begin::Value*/}
                  <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1">
                     
                    6,020
                  </span>
                  {/*end::Value*/}
                  {/*begin::Label*/}
                  <span className="badge badge-light-success fs-base">
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.5"
                          x={13}
                          y={6}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(90 13 6)"
                          fill="currentColor"
                        />
                        <path
                          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}2.6%
                  </span>
                  {/*end::Label*/}
                </div>
                {/*end::Statistics*/}
                {/*begin::Description*/}
                <span className="fs-6 fw-semibold text-gray-400">
                  Weekly invoice count
                </span>
                {/*end::Description*/}
              </div>
              {/*end::Statistics*/}
              {/*begin::Chart*/}
              <div
                id="kt_card_widget_9_chart"
                className="min-h-auto"
                style={{ height: 125 }}
              />
              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 5*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          {/*begin::Card widget 6*/}
          <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            {/*begin::Header*/}
            <div className="card-header pt-5">
              {/*begin::Title*/}
              <div className="card-title d-flex flex-column">
                {/*begin::Info*/}
                <div className="d-flex align-items-center">
                  {/*begin::Currency*/}
                  <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                    $
                  </span>
                  {/*end::Currency*/}
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    2,420,621
                  </span>
                  {/*end::Amount*/}
                  {/*begin::Badge*/}
                  <span className="badge badge-light-primary fs-base">
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.5"
                          x={13}
                          y={6}
                          width={13}
                          height={2}
                          rx={1}
                          transform="rotate(90 13 6)"
                          fill="currentColor"
                        />
                        <path
                          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}2.6%
                  </span>
                  {/*end::Badge*/}
                </div>
                {/*end::Info*/}
                {/*begin::Subtitle*/}
                <span className="text-gray-400 pt-1 fw-semibold fs-5">
                  Duplicate Invoice Number
                </span>
                {/*end::Subtitle*/}
              </div>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end px-0 pb-0">
              {/*begin::Chart*/}
              <div
                id="kt_card_widget_6_chart"
                className="w-100"
                style={{ height: 80 }}
              />
              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 6*/}
          {/*begin::Card widget 7*/}
          <div className="card card-flush h-md-50 mb-xl-10">
            {/*begin::Header*/}
            <div className="card-header pt-5">
              {/*begin::Title*/}
              <div className="card-title d-flex flex-column">
                {/*begin::Amount*/}
                <div className="d-flex align-items-center">
                  {/*begin::Currency*/}
                  <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                    $
                  </span>
                  {/*end::Currency*/}
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    81,029 
                  </span>
                  {/*end::Amount*/}
                </div>
                {/*end::Amount*/}
                {/*begin::Subtitle*/}
                <span className="text-gray-400 pt-1 fw-semibold fs-6">
                  Top supplier spend
                </span>
                {/*end::Subtitle*/}
              </div>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex flex-column justify-content-end pe-0">
              {/*begin::Title*/}
              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                Supplier Name
              </span>
              {/*end::Title*/}
              {/*begin::Users group*/}
              <div className="symbol-group symbol-hover flex-nowrap">
                <div
                  className="symbol symbol-40px symbol-circle"
                  data-bs-toggle="tooltip"
                  title="Innovation SALES"
                >
                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                    1
                  </span>
                </div>
                <div
                  className="symbol symbol-40px symbol-circle"
                  data-bs-toggle="tooltip"
                  title="STAHL TBM MANAGEMENT CO LLC"
                >
                  <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                    2
                  </span>
                </div>
                <div
                  className="symbol symbol-40px symbol-circle"
                  data-bs-toggle="tooltip"
                  title="HEWLETT PACKARD ENTERPRISE COMPANY"
                >
                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                    3
                  </span>
                </div>
              </div>
              {/*end::Users group*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 7*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
          {/*begin::Chart widget 3*/}
          <div className="card card-flush overflow-hidden h-md-100">
            {/*begin::Header*/}
            <div className="card-header py-5">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Payment Status
                </span>
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                {/*begin::Menu*/}
                <button
                  className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-overflow="true"
                >
                  {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                  <span className="svg-icon svg-icon-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={4}
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={11}
                        width="2.6"
                        height="2.6"
                        rx="1.3"
                        fill="currentColor"
                      />
                      <rect
                        x={15}
                        y={11}
                        width="2.6"
                        height="2.6"
                        rx="1.3"
                        fill="currentColor"
                      />
                      <rect
                        x={7}
                        y={11}
                        width="2.6"
                        height="2.6"
                        rx="1.3"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </button>
                {/*begin::Menu 2*/}
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                  data-kt-menu="true"
                >
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                      Quick Actions
                    </div>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator mb-3 opacity-75" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                    >
                      New Ticket
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                    >
                      New Customer
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div
                    className="menu-item px-3"
                    data-kt-menu-trigger="hover"
                    data-kt-menu-placement="right-start"
                  >
                    {/*begin::Menu item*/}
                    <a
                      href="#"
                      className="menu-link px-3"
                    >
                      <span className="menu-title">New Group</span>
                      <span className="menu-arrow" />
                    </a>
                    {/*end::Menu item*/}
                    {/*begin::Menu sub*/}
                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                        >
                          Admin Group
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                        >
                          Staff Group
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                        >
                          Member Group
                        </a>
                      </div>
                      {/*end::Menu item*/}
                    </div>
                    {/*end::Menu sub*/}
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                    >
                      New Contact
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu separator*/}
                  <div className="separator mt-3 opacity-75" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <div className="menu-content px-3 py-3">
                      <a
                        className="btn btn-primary btn-sm px-4"
                        href="#"
                      >
                        Generate Reports
                      </a>
                    </div>
                  </div>
                  {/*end::Menu item*/}
                </div>
                {/*end::Menu 2*/}
                {/*end::Menu*/}
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex justify-content-center flex-column pb-1 px-0">
              {/*begin::Chart*/}
              <div
                id="kt_charts_widget_stutas_payment_chart"
                className="min-h-auto ps-4 pe-6"
                style={{ height: 350 }}
              />
              {/*end::Chart*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Chart widget 3*/}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row1*/}
      {/*begin::Row2*/}
      <div className="row gy-5 g-xl-10 mx-1">
        {/*begin::Card*/}
        <div className="card">
          {/*begin::Card header*/}
          <div className="card-header border-0 pt-6">
            {/*begin::Card title*/}
            <div className="card-title">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Invoice and Payment
                </span>
                <span className="text-gray-400 mt-1 fw-semibold fs-6">
                  Total 2,356 Items
                </span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*begin::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::Actions*/}
              <div className="card-toolbar">
                {/*begin::Filters*/}
                <div className="d-flex flex-stack flex-wrap gap-4">
                  {/*begin::Destination*/}
                  <div className="d-flex align-items-center fw-bold">
                    {/*begin::Label*/}
                    <div className="text-muted fs-7 me-2">Cateogry</div>
                    {/*end::Label*/}
                    {/*begin::Select*/}
                    <select
                      className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                      data-control="select2"
                      data-hide-search="true"
                      data-dropdown-css-class="w-150px"
                      data-placeholder="Select an option"
                    >
                      <option />
                      <option value="Show All" selected="selected">
                        Show All
                      </option>
                      <option value="a">Category A</option>
                      <option value="b">Category B</option>
                    </select>
                    {/*end::Select*/}
                  </div>
                  {/*end::Destination*/}
                  {/*begin::Status*/}
                  <div className="d-flex align-items-center fw-bold">
                    {/*begin::Label*/}
                    <div className="text-muted fs-7 me-2">Status</div>
                    {/*end::Label*/}
                    {/*begin::Select*/}
                    <select
                      className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                      data-control="select2"
                      data-hide-search="true"
                      data-dropdown-css-class="w-150px"
                      data-placeholder="Select an option"
                      data-kt-table-widget-5="filter_status"
                    >
                      <option />
                      <option value="Show All" selected="selected">
                        Show All
                      </option>
                      <option value="In Stock">In Stock</option>
                      <option value="Out of Stock">Out of Stock</option>
                      <option value="Low Stock">Low Stock</option>
                    </select>
                    {/*end::Select*/}
                  </div>
                  {/*end::Status*/}
                  {/*begin::Search*/}
                  <a
                    href="apps/ecommerce/catalog/products.html"
                    className="btn btn-light btn-sm"
                  >
                    View Stock
                  </a>
                  {/*end::Search*/}
                </div>
                {/*begin::Filters*/}
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed fs-6 gy-5"
                id="kt_subscriptions_table"
              >
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr>
                    <th className="min-w-80px">Status</th>
                    <th className="min-w-200px">
                      DataSource
                      <a
                        href="#"
                        className="text-hover-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                        <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </a>
                    </th>
                    <th className="min-w-200px">Co</th>
                    <th className="min-w-200px">VndNum</th>
                    <th className="min-w-200px">VndName</th>
                    <th className="min-w-200px">InvNum</th>
                    <th className="min-w-200px">InvDate</th>
                    <th className="min-w-200px">InvAmt</th>
                    <th className="min-w-80px">CurrCode</th>
                    <th className="min-w-100px">PmtNum</th>
                    <th className="min-w-100px">PmtDate</th>
                    <th className="min-w-100px">PmtAmt</th>
                    <th className="min-w-100px">PmtTypeDesc</th>
                    {/*end::Table row*/}
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-semibold">
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>E221178</td>
                    <td>Bedrock Facilities</td>
                    <td>57439</td>
                    <td>Innovation SALES.</td>
                    <td>2120039086391</td>
                    <td>10/17/2022</td>
                    <td>11,212.39 $</td>
                    <td>USD</td>
                    <td />
                    <td />
                    <td />
                    <td>SUP</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>E201111</td>
                    <td>Bedrock Facilities</td>
                    <td>6461647</td>
                    <td>STAHL TBM MANAGEMENT CO LLC</td>
                    <td>INV Q1 11.19-01.20 R</td>
                    <td>1/31/2020</td>
                    <td>94,703.13 $</td>
                    <td>USD</td>
                    <td>10397117</td>
                    <td>06/08/2020</td>
                    <td>$94,703.13</td>
                    <td>Wire</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>E220624</td>
                    <td>Bedrock Facilities</td>
                    <td>81:7749:30392</td>
                    <td>HEWLETT PACKARD ENTERPRISE COMPANY</td>
                    <td>637542</td>
                    <td>6/5/2022</td>
                    <td>28,540.78 $</td>
                    <td>USD</td>
                    <td>PmtNum</td>
                    <td>217588</td>
                    <td>06/28/2018</td>
                    <td>$28,540.78</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>E221106</td>
                    <td>Bedrock Facilities</td>
                    <td> 379049</td>
                    <td>FEDEX</td>
                    <td>7-910-25559</td>
                    <td>10/11/2022</td>
                    <td>$ 2,773.72</td>
                    <td>USD</td>
                    <td>TD</td>
                    <td />
                    <td />
                    <td>UTL</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-info">
                        Pending
                      </div>
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028686292</td>
                    <td>7/21/2021</td>
                    <td>$ 15,543.8</td>
                    <td>USD</td>
                    <td>312125</td>
                    <td>1/11/2022</td>
                    <td>17,963.26 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-info">
                        Pending
                      </div>
                    </td>
                    <td>E220624</td>
                    <td>Bedrock Dev</td>
                    <td>81:7413:60673</td>
                    <td>ARIBA INC</td>
                    <td>6028686292</td>
                    <td>10/25/2021</td>
                    <td>$ 2,224.39</td>
                    <td>USD</td>
                    <td>354843</td>
                    <td>4/28/2022</td>
                    <td>17,768.19 $</td>
                    <td>OUTSOURCED_CHECK</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-danger">
                        Cancelled
                      </div>
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028594094</td>
                    <td>5/27/2021</td>
                    <td>$ 37,592.48</td>
                    <td>USD</td>
                    <td>284253</td>
                    <td>9/1/2021</td>
                    <td>37,592.48 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028050139</td>
                    <td>12/2/2019</td>
                    <td>$ 3.68</td>
                    <td>USD</td>
                    <td>300369</td>
                    <td>11/17/2021</td>
                    <td>151.02 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Digital Media, LLC</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028881931</td>
                    <td>2/11/2022</td>
                    <td>$ 31.39</td>
                    <td>EUR</td>
                    <td>329962</td>
                    <td>4/18/2022</td>
                    <td>544.83 $</td>
                    <td>International WIRE</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Digital Media, LLC</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028580362</td>
                    <td>4/23/2021</td>
                    <td>$ 8,171.63</td>
                    <td>USD</td>
                    <td>323432</td>
                    <td>3/11/2022</td>
                    <td>11,600.12 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028564704</td>
                    <td>4/19/2021</td>
                    <td>$ 7,019.43</td>
                    <td>USD</td>
                    <td>300087</td>
                    <td>11/15/2021</td>
                    <td>65,730.14 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-danger">
                        Cancelled
                      </div>
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028537257</td>
                    <td>3/24/2021</td>
                    <td>$ 7,019.43</td>
                    <td>USD</td>
                    <td>261360</td>
                    <td>5/3/2021</td>
                    <td>7,767.94 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028724945</td>
                    <td>8/23/2021</td>
                    <td>$ 0.66</td>
                    <td>USD</td>
                    <td>299305</td>
                    <td>11/12/2021</td>
                    <td>2,949.97 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028606205</td>
                    <td>5/29/2021</td>
                    <td>$ 13,100.07</td>
                    <td>USD</td>
                    <td>300087</td>
                    <td>11/15/2021</td>
                    <td>65,730.14 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Facilities</td>
                    <td>100125</td>
                    <td>ARIBA INC</td>
                    <td>6028603097</td>
                    <td>5/28/2021</td>
                    <td>$ 52.41</td>
                    <td>USD</td>
                    <td>299305</td>
                    <td>11/12/2021</td>
                    <td>2,949.97 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Digital Media, LLC</td>
                    <td />
                    <td>ARIBA INC</td>
                    <td>6028872429</td>
                    <td>1/13/2022</td>
                    <td>$ 340.91</td>
                    <td>EUR</td>
                    <td>329962</td>
                    <td>4/18/2022</td>
                    <td>544.83 $</td>
                    <td>International WIRE</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Digital Media, LLC</td>
                    <td />
                    <td>ARIBA INC</td>
                    <td>6028698319</td>
                    <td>7/21/2021/td&gt;</td>
                    <td>$ 3,428.49</td>
                    <td>USD</td>
                    <td>323432</td>
                    <td>3/11/2022</td>
                    <td>11,600.12 $</td>
                    <td>Electronic</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge py-5 px-3 badge-light-success">
                        Paid
                      </div> 
                    </td>
                    <td>C220624</td>
                    <td>Bedrock Dev</td>
                    <td>81:7749:30392</td>
                    <td>HEWLETT PACKARD ENTERPRISE COMPANY</td>
                    <td>1011425</td>
                    <td>1/25/2018</td>
                    <td>$ 2727.83</td>
                    <td>CREDIT</td>
                    <td />
                    <td />
                    <td>0$</td>
                    <td />
                  </tr>
                  {/* end::record6 */}
                </tbody>
                {/*end::Table body*/}
              </table>
              <div className="row py-5">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div
                    className="dataTables_length"
                    id="kt_customers_table_length"
                  >
                    <label>
                      <select
                        name="kt_customers_table_length"
                        aria-controls="kt_customers_table"
                        className="form-select form-select-sm form-select-solid"
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className="paginate_button page-item previous disabled"
                        id="kt_customers_table_previous"
                      >
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={0}
                          tabIndex={0}
                          className="page-link"
                        >
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="paginate_button page-item active">
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={1}
                          tabIndex={0}
                          className="page-link"
                        >
                          1
                        </a>
                      </li>
                      <li className="paginate_button page-item">
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={2}
                          tabIndex={0}
                          className="page-link"
                        >
                          2
                        </a>
                      </li>
                      <li className="paginate_button page-item">
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={3}
                          tabIndex={0}
                          className="page-link"
                        >
                          3
                        </a>
                      </li>
                      <li className="paginate_button page-item">
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={4}
                          tabIndex={0}
                          className="page-link"
                        >
                          4
                        </a>
                      </li>
                      <li
                        className="paginate_button page-item next"
                        id="kt_customers_table_next"
                      >
                        <a
                          aria-controls="kt_customers_table"
                          data-dt-idx={5}
                          tabIndex={0}
                          className="page-link"
                        >
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*end::Table*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Card*/}
      </div>
      {/*end::Row2*/}
      {/* end::hala Add */}
    </div>
    {/*end::Content*/}
  </div>
  {/*end::Content wrapper*/}
 
</div>


    </LayoutPrevent>
  );
};

export default OverviewInView;
