import React from "react";
import { MenuItem, SubItem } from "../../index";

const HeaderAudit = () => {
  return (
    <>
      <SubItem title="Back" namePage="home" isNotSubMenu={true}/>
      <SubItem title="Overview" namePage="audit_overview" isNotSubMenu={true}/>
      <StatementsItem />
      <ClaimsItem />
    </>
  );
};

export default HeaderAudit;

const StatementsItem = () => (
  <MenuItem title="Statements">
    <SubItem title="Overview" namePage="overview_statements"/>
    <SubItem title="Collected" namePage="collected_statements"/>
    <SubItem title="Outstanding" namePage="outstanding_statements"/>
    <SubItem title=" Upload Statement" namePage="upload_statements"/>
  </MenuItem>
);

const ClaimsItem = () => (
  <MenuItem title="Claims">
    <SubItem title="Overview" namePage="overview_Claims"/>
    <SubItem title="All Claims" namePage="all_Claims"/>
    <SubItem title="New Claim " namePage="new_claims"/>
  </MenuItem>
);