import React from "react";
import { MenuItem, SubItem } from "../../index";

const HeaderPrevent = () => {
  return (
    <>
      <SubItem title="Back" namePage="home" isNotSubMenu={true} />
      <SubItem
        title="Overview"
        namePage="prevent_overview"
        isNotSubMenu={true}
      />
      <PreventItems />
      <SubItem title="Inview" namePage="inview_overview" isNotSubMenu={true} />
    </>
  );
};

export default HeaderPrevent;

function PreventItems() {
  return (
    <MenuItem title="Prevent">
      <SubItem title="Pending" namePage="upload_overview/Pending" />
      <SubItem title="Stop" namePage="upload_overview/Stop" />
      <SubItem title="Process" namePage="upload_overview/Process" />
    </MenuItem>
  );
}
